@import '../shared/constants';

.root {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $index-2;
  height: $statusBar-height;
  line-height: $statusBar-height;
  padding: 0 15px;
  background: #000000;
  color: $color-gray;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &,
  button {
    font-size: 11px;
  }

  div {
    &.instance {
      position: absolute;
      top: 0;
      left: 15px;
    }

    margin-right: 15px;

    span {
      &:first-child {
        color: #FFFFFF;
      }

      &:last-child {
        color: $color-secondary;
      }
    }
  }
}
