@import './shared/constants';

.container {
  position: fixed;
  z-index: $index-0;
  top: 0;
  left: 0;
  right: 0;
  bottom: $statusBar-height;
  min-width: 1200px;

  .content {
    position: absolute;
    top: 0;
    left: $menu-width;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
  }
}

