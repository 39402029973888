@import "~normalize-scss/sass/normalize/import-now";
@import "./shared/constants";

body {
  background-color: #171717;;
}

body,
pre,
button,
input {
  font-family: 'Libre Franklin', sans-serif;
  font-size: 12px;
  color: $color-default;
}

pre {
  margin: 0;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 1;
  }

}

*:focus {
  outline: none;
}
