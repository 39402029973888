@import '../shared/constants';

.root {
  &,
  button,
  a {
    color: #000000;
    text-decoration: none;
  }

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: $menu-width;
  background-color: #FFFFFF;
  overflow: auto;
  padding-bottom: 10px;

  & > a {
    &,
    img {
      display: block;
    }

    &.logo {
      padding: 20px 0 5px 7px;

      img {
        height: 40px;
      }
    }
  }

  & > div {
    padding: 10px 0 0 10px;

    span,
    button,
    a {
      display: block;
      text-align: left;
      padding: 5px;
    }

    span {
      font-weight: bold;
    }

    .section {
      margin-left: 11px;

      &.placeholder,
      &.placeholder:hover {
        opacity: 0.5;
        cursor: default;
      }

      &.active {
        font-weight: bold;
        color: darken($color-secondary, 8);
      }
    }
  }
}
