@import '../shared/constants';

.root {
  color: $color-default;
  background: #1a1a1a;
  overflow: auto;
  display: flex;
  flex-direction: row;
  padding: 15px 25px;
  .section {
    padding-right: 25px;
    height: 45px;

    &:last-child {
      border-right-width: 0;
      margin-right: 0;
    }

    & > div {
      &:first-child {
        color: $color-gray;
        padding-bottom: 5px;
        font-size: 10px;
      }

      &:nth-child(3) {
        color: $color-secondary;
        padding-top: 3px;
        font-size: 10px;
        &.danger {
          color: $color-danger;
        }
      }
    }
  }
}
