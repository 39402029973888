@import '../constants';

.root {
  line-height: 24px;
  height: 24px;
  padding: 0 5px;
  margin: 0;
  font-size: 11px;
  border: 0;
  color: $color-secondary;
  display: inline-block;
  background: #3e3e3e;
}
