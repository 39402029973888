@import '../constants';

.root {
  color: $color-default;
  padding: 0;
  margin: 0;
  input {
    position: absolute;
    top: -100000px;
  }
}
