@import '../shared/constants';

.root {
  background: #1a1a1a;
  flex-direction: column;

  .triggers {
    text-align: left;

    button {
      border: 0;
      padding: 0 25px;
      margin-right: 5px;
      color: $color-gray;
      line-height: 36px;

      &.active {
        background-color: #060606;
        color: $color-default;
      }

      &.disabled {
        opacity: 0.3;
        cursor: default;
      }
    }
  }

  .content {
    color: $color-default;
    background: #060606;
    padding: 15px 25px;
    max-height: 250px;
    overflow: auto;
    position: relative;
    .logs,
    .notifications {
      button {
        position: absolute;
        top: 15px;
        right: 15px;
      }
      & > div {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
