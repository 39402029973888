@import '../shared/constants';

.root {
  flex: 1;
  overflow: auto;
  flex-direction: column;
  background: #292929;
  color: #FFFFFF;
  padding: 20px 25px;

  h1, h2, h3 {
    margin: 0;
  }
  h1 {
    margin-bottom: 20px;
  }
}
