.root {
  margin-bottom: 10px;
  & > div:first-child {
    margin-bottom: 5px;
    color: #bababa;
  }

  & > div:last-child {
    display: flex;
    flex-direction: row;
  }
}
