@import '../constants';

.root {
  background: $color-primary;
  color: #FFFFFF;
  line-height: 24px;
  height: 24px;
  padding: 0 20px;
  margin: 0;
  font-size: 11px;
  display: inline-block;
  text-shadow: 1px 1px #000000;
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  &.danger {
    background: $color-danger;
  }
}
