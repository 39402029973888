$menu-width: 265px;
$statusBar-height: 30px;

$index-0: 1000;
$index-1: 10000;
$index-2: 100000;

$color-default: #ffffff;
$color-gray: #a3a3a3;
$color-primary: #6c4ebd;
$color-danger: #bd0c50;
$color-secondary: #27E270;
