@import '../shared/constants';

.root {
  $size: 40px;
  position: fixed;
  z-index: $index-2;
  top: 0;
  left: 0;
  right: 0;
  bottom: $statusBar-height;
  background-color: rgba(0, 0, 0, 0.4);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $size;
    height: $size;
    margin-top: ($size / 2px) - $statusBar-height;
    margin-left: -($size / 2)px;
  }
}
