.root {
  code {
    background-color: transparent;
    font-family: Menlo, sans-serif;
    font-size: 11px;
  }

  &.javascript {
    background: #3e3e3e;
    padding: 10px;
    margin-bottom: 25px;
  }

  &.json {
    //
  }
}
